import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../login/loginModel';
import { TipoUsuarioModel, UsuarioModel } from './usuariosModel';
import { UsuariosService } from './usuariosService';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  FormUsuarios: UsuarioModel;
  ListaUsuarios: UsuarioModel[];
  ListaTipoUsuario: TipoUsuarioModel[];
  ColunasUsuarios: any[];
  Carregado: boolean = false;
  ModoCadastro: boolean = false;
  Editando: boolean = false;
  btnNovoText: string = "Novo";

  constructor(
    private globalFunc: GlobalFunctions,
    private usuariosService: UsuariosService
  ) { }

  ngOnInit() {
    this.ColunasUsuarios = [
      { field: 'idusuarios', header: 'ID' },
      { field: 'nome', header: 'Nome' },
      { field: 'usuario', header: 'Usuário' },
      { field: 'habilitado', header: 'Habilitado' },
    ];
    this.AtualizarDados();
  }

  AtualizarDados() {
    this.Carregado = false;
    this.FormUsuarios = new UsuarioModel();
    this.usuariosService.GetAllTipoUsuario().then(resTipoUsuario => {
      this.ListaTipoUsuario = resTipoUsuario;
      this.usuariosService.GetAllUsuarios().then(resUsuarios => {
        this.ListaUsuarios = resUsuarios;
        this.Carregado = true;
        this.ModoCadastro = false;
        this.Editando = false;
      });
    });
  }

  Novo() {
    this.FormUsuarios = new UsuarioModel();
    if (this.btnNovoText == "Novo") {
      this.ModoCadastro = true;
      this.Editando = false;
      this.btnNovoText = "Cancelar";
    }
    else {
      this.ModoCadastro = false;
      this.Editando = false;
      this.btnNovoText = "Novo";
    }
  }

  Salvar() {
    console.log(this.FormUsuarios);
    let Erro: string = this.globalFunc.VerificaCaractereNoCampo(this.FormUsuarios, ["", null], ["idusuarios", "insert_date", "update_date"]);
    if (Erro) {
      alert("Preencha corretamente os campos!\r\nCampo obrigatório: " + Erro);
      return;
    }

    if (!this.Editando) {
      this.usuariosService.InsertUsuario(this.FormUsuarios).then(resInsert => {
        this.FinalizaAlteracoes(resInsert);
      });
    }
    else {
      this.usuariosService.UpdateUsuario(this.FormUsuarios).then(resUpdate => {
        this.FinalizaAlteracoes(resUpdate);
      });
    }
  }

  FinalizaAlteracoes(response: ResponseModel) {
    if (response.status == 200) {
      alert(`Usuário ${(!this.Editando) ? 'cadastrado' : 'atualizado'} com sucesso!`);
      this.AtualizarDados();
    }
    else {
      alert(`Erro ao ${(!this.Editando) ? 'cadastrar' : 'atualizar'} usuário!`);
      console.log(response.error);
    }
    this.Novo();
  }

  Editar(Usuario: UsuarioModel) {
    this.Novo();
    this.FormUsuarios = new UsuarioModel();
    this.FormUsuarios.habilitado = Usuario.habilitado;
    this.FormUsuarios.idtipousuario = Usuario.idtipousuario;
    this.FormUsuarios.idusuarios = Usuario.idusuarios;
    this.FormUsuarios.nome = Usuario.nome;
    this.FormUsuarios.senha = Usuario.senha;
    this.FormUsuarios.senha1 = Usuario.senha;
    this.FormUsuarios.usuario = Usuario.usuario;
    this.Editando = true;
  }

  Excluir(Usuario: UsuarioModel) {
    if (!confirm("Tem certeza que deseja excluir esse usuário?"))
      return;

    this.usuariosService.DeleteUsuario(Usuario.idusuarios).then(resDelete => {
      if (resDelete.status == 200) {
        alert("Usuário excluído com sucesso!");
        this.AtualizarDados();
      }
      else {
        alert("Erro ao excluir usuário!");
      }
    });
  }

  MudarStatus(Usuario: UsuarioModel) {
    this.usuariosService.MudaHabUsuario(Usuario.idusuarios).then(resUpdate => {
      if (resUpdate.status == 200) {
        this.AtualizarDados();
      }
      else {
        alert("Erro ao mudar status do usuário!");
        console.log(resUpdate.error);
      }
    });
  }

}
