// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const Porta: number = 1343;

export const environment = {
  production: true,
  Versao: "1.0.5"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class GlobalFunctions {

  APIUrl() : string {
    let URL: string = "http://54.94.231.207:3030/";
    let Link: string = window.location.href.split('/')[2];

    if (window.location.href.includes("https")) {
      URL = "https://" + Link;
    }
    else {
      if (!Link.includes(":4200"))
        URL = "http://" + Link;
      else
        URL = "http://" + Link.split(':')[0] + ((environment.production) ? "" : ":" + Porta);
    }

    //URL = "http://54.94.231.207:3030";

    return URL;
  }

  GeraCorAleatoria(): string {
    let D1 = Math.round(Math.random() * 100) - 1;
    let D1s = "" + D1;
    if (D1 < 10)
      D1s = "0" + D1;
    let D2 = Math.round(Math.random() * 100) - 1;
    let D2s = "" + D2;
    if (D2 < 10)
      D2s = "0" + D2;
    let D3 = Math.round(Math.random() * 100) - 1;
    let D3s = "" + D3;
    if (D3 < 10)
      D3s = "0" + D3;
    let CorHex = "#" + D1s + D2s + D3s;
    return CorHex;
  }

  OrdenarLista(ListaEntrada: any[], Campo: string, Crescente: boolean): any[] {
    let Lista: any[] = new Array<any>();
    for (let i = 0; i < ListaEntrada.length; i++) {
      Lista.push(ListaEntrada[i]);
      Lista[i]['inserido'] = false;
    }
    let NovaLista: any[] = new Array<any>();
    let MenorValor: number = (Crescente) ? 99999999 : -99999999;
    let Index: number = 0;
    for (let i = 0; i < Lista.length; i++) {
      for (let o = 0; o < Lista.length; o++) {
        if (Crescente && <any>Lista[o][Campo] < MenorValor && !Lista[o]['inserido']) {
          Index = o;
          MenorValor = Lista[o][Campo];
        }
        else if (!Crescente && <any>Lista[o][Campo] > MenorValor && !Lista[o]['inserido']) {
          Index = o;
          MenorValor = Lista[o][Campo];
        }
      }
      Lista[Index]['inserido'] = true;
      MenorValor = (Crescente) ? 99999999 : -99999999;
      NovaLista.push(Lista[Index]);
    }

    return NovaLista;
  }

  VerificaCaractereNoCampo(Formulario: any, Caracteres: any[], Excecoes: any[], QtdeCampos: number = 0, Igual: boolean = true): string {
    let Resultado: string = null;
    let CamposLidos: number = 0;
    for (var Campo in Formulario) {
      for (let o = 0; o < Caracteres.length; o++) {
        let Ex: boolean = false;
        for (let u = 0; u < Excecoes.length; u++) {
          if (Campo === Excecoes[u]) {
            Ex = true;
            break;
          }
        }
        if (Ex)
          break;
        if (Igual) {
          if (Formulario[Campo] === Caracteres[o]) {
            Resultado = Campo;
            break;
          }
        }
        else {
          if (Formulario[Campo] !== Caracteres[o]) {
            Resultado = Campo;
            break;
          }
        }
      }
      CamposLidos++;
    }
    if (CamposLidos < QtdeCampos)
      Resultado = CamposLidos.toString();
    return Resultado;
  }

  uploadFiles(headers: any[], metodo: string, url: string, VariavelFinal: string) {
    localStorage.setItem(VariavelFinal, "0");
    let req = new XMLHttpRequest();
    let formData = new FormData();

    for (let i = 0; i < headers.length; i++) {
      formData.append(headers[i].header, headers[i].data);
    }
    req.open(metodo, this.APIUrl() + url);
    req.send(formData);

    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        localStorage.setItem(VariavelFinal, req.response);
      }
    }
  }

  chamaScript(document, elementRef, name, id = "") {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '../assets/scpts/' + name;
    s.id = id;
    const __this = this;
    s.onload = function () { __this.afterScriptAdded(); };
    elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };
    if (typeof (window['functionFromExternalScript']) === 'function') {
      window['functionFromExternalScript'](params);
    }
  }

}