import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { AulasService } from '../aulas/aulasService';
import { JogosAlunosModel } from '../jogos/jogosModel';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  linkJogo: SafeResourceUrl;
  JogoCarregado: boolean = false;
  formJogo: JogosAlunosModel = new JogosAlunosModel();
  modalText: string;
  modalType: number;


  constructor(
    private globalFunc: GlobalFunctions,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private AulasService: AulasService,
  ) { }

  ngOnInit(): void {
      localStorage.setItem("isGameOver", "0");
      localStorage.setItem("failed", "0");
      this.gameOver();
      setTimeout(() => {
        let link = "https://" + window.location.href.split("/")[2] + "/jogos/" + window.location.href.split("/")[5];
        //let linkTeste = "http://worldstationidiomas.mooo.com/jogos/4";
        this.linkJogo = this.sanitizer.bypassSecurityTrustResourceUrl(link);
        this.JogoCarregado = false;
      }, 500);
      setTimeout(() => {
        this.JogoCarregado = true;
        this.formJogo.idjogos = parseInt(window.location.href.split("/")[5]);
        this.formJogo.idusuarios = parseInt(localStorage.getItem("idusuarios"));
      }, 1500);

    this.globalFunc.chamaScript(this.document, this.elementRef, "setPlayerWidth.js", "PlayerWidth");
    this.globalFunc.chamaScript(this.document, this.elementRef, "rotatePlayer.js", "rotatePlayer");
  }

  gameOver() {
    setTimeout(() => {
      if(localStorage.getItem("isGameOver") == "0") {
        if(localStorage.getItem("failed") == "1") {
          this.formJogo.sucesso = false;
          this.formJogo.concluiu = true;
          this.AulasService.AtualizarJogoAluno(this.formJogo).then(response => {
            this.router.navigate(["/aulas"]);
          });
        }
        else {
          this.gameOver();
        }
      }
      else {
        this.formJogo.sucesso = true;
        this.formJogo.concluiu = true;
        this.AulasService.AtualizarJogoAluno(this.formJogo).then(response => {
          this.router.navigate(["/aulas"]);
        });
      }
    }, 1000);
  }

  OpenModal(type) {
    let exitCont = document.querySelector(".exit-container") as HTMLElement;
    let exitModal = document.querySelector(".exit-modal") as HTMLElement;

    if(type === 1) {
      this.modalText = "Do you wish to return to the homepage?";
      this.modalType = type;
    }  else if (type === 2) {
      this.modalText = "Do you wish to log out?";
      this.modalType = type;
    }

    exitCont.style.display = "flex";
    setTimeout(() => {
      exitCont.style.opacity = "1";
      setTimeout(() => {
        exitModal.style.transform = "scale(1.1)"
        setTimeout(() => {
          exitModal.style.transform = "scale(1)"
        }, 200);
      }, 400);
    }, 50);
  }

  Exit(exit) {
    let exitCont = document.querySelector(".exit-container") as HTMLElement;
    let exitModal = document.querySelector(".exit-modal") as HTMLElement;

    if(this.modalType === 1) {
      if(exit === 1) {
        this.router.navigate(['/aulas']);
      } else if(exit === 2){
        exitModal.style.transform = "scale(1.1)";
        setTimeout(() => {
          exitModal.style.transform = "scale(0)";
          setTimeout(() => {
            exitCont.style.opacity = "0";
            setTimeout(() => {
              exitCont.style.display = "none";
            }, 350);
          }, 400);
        }, 300);
      }
    } else if(this.modalType) {
      if(exit === 1) {
        this.router.navigate(['/']);
        localStorage.clear();
      } else if(exit === 2){
        exitModal.style.transform = "scale(1.1)";
        setTimeout(() => {
          exitModal.style.transform = "scale(0)";
          setTimeout(() => {
            exitCont.style.opacity = "0";
            setTimeout(() => {
              exitCont.style.display = "none";
            }, 350);
          }, 400);
        }, 300);
      }
    }
  }

}
